enum ORDER_CHANGELOG_TYPE {
	CHANGE_ITEM_QUANTITY = 'change_item_quantity',
	CANCELLED_ITEM = 'cancelled_item',
	CHANGE_DISCOUNT = 'change_discount',
	CANCELLED_ORDER_SPLIT = 'cancelled_order_split',
	CANCELLED_ORDER_NO_ITEMS = 'cancelled_order_no_items',
	CANCELLED_ORDER_MOVED = 'cancelled_order_moved',
	SPLIT_ITEM_SOME = 'split_item_some',
	SPLIT_ITEM_WHOLE = 'split_item_whole',
	MOVE_ITEM_SOME = 'move_item_some',
	MOVE_ITEM_WHOLE = 'move_item_whole',
	UPDATE_ORDER_PREP_TIME = 'update_order_prep_time',
	CUSTOMER_ACCEPTED_PREP_TIME_UPDATES = 'customer_accepted_prep_time_updates',
	CUSTOMER_REJECTED_PREP_TIME_UPDATES = 'customer_rejected_prep_time_updates',
}

/** @deprecated start using embedded `base_currency` and `currencies_configs` instead */
enum EXCHANGE_RATE {
	USD_KHR = 4150,
}

/**
 * Built in payment types, each venue will have its own custom payment types to be used at Till on top of these.
 * This is a broad enum, will soon migrate to a specific enum that contain subset of this enum for each use case
 * */
enum PAYMENT_TYPE {
	KPAY_CARD = 'kpay_card',
	KPAY_QR = 'kpay_qr',
	KPAY_OCTOPUS = 'kpay_octopus',
	KPAY_PAYME = 'kpay_payme',
	/** Any payment come in through Served's PayWay integration */
	SERVED_PAYWAY = 'served_payway',
	/** ABA PAY payment at venue */
	ABA_PAY = 'aba_pay',
	/** KHQR payment at venue */
	KHQR = 'khqr',
	/** or `Pay Later`, should NOT be stored as `payment_type`, only for displaying purpose */
	TAB = 'tab',
}

enum PAYMENT_TYPE_TITLE {
	kpay_card = 'KPay Card',
	kpay_qr = 'KPay QR',
	kpay_octopus = 'KPay Octopus',
	kpay_payme = 'KPay PayMe',
	served_payway = 'Served - PayWay',
	aba_pay = 'ABA PAY',
	khqr = 'KHQR',
	tab = 'Tab',
}

enum ORDER_STATUS {
	PLACED = 'placed',
	PREPARING = 'preparing',
	PROCESSED = 'processed',
	/** @deprecated use `PROCESSED` */
	READY_TO_COLLECT = 'ready_to_collect',
	/** @deprecated use `PROCESSED` */
	ON_THE_WAY = 'on_the_way',
	/** @deprecated use `is_cancelled` flag */
	CANCELLED = 'cancelled',
}

enum USER_ROLE {
	SUPER_ADMIN = 'super_admin',
	ADMIN = 'admin',
	MANAGER = 'manager',
	SUPERVISOR = 'supervisor',
	STAFF = 'staff',
	JUNIOR_STAFF = 'junior_staff',
}

enum OPS_PAGE {
	TILL = 'till',
	ORDERS = 'orders',
	LOCATIONS = 'locations',
	KDS = 'kds',
	RESERVATION = 'reservation',
}

enum USER_PERMISSIONS {
	VIEW_ORDER = 'VIEW_ORDER',
	CREATE_ORDER = 'CREATE_ORDER',
	EDIT_ORDER_BEFORE_BILL_PRINTED = 'EDIT_ORDER_BEFORE_BILL_PRINTED',
	EDIT_ORDER_AFTER_BILL_PRINTED = 'EDIT_ORDER_AFTER_BILL_PRINTED',
	EDIT_ORDER_BEFORE_PAYMENT = 'EDIT_ORDER_BEFORE_PAYMENT',
	EDIT_ORDER_AFTER_PAYMENT = 'EDIT_ORDER_AFTER_PAYMENT',
	CANCEL_ORDER_BEFORE_BILL_PRINTED = 'CANCEL_ORDER_BEFORE_BILL_PRINTED',
	CANCEL_ORDER_AFTER_BILL_PRINTED = 'CANCEL_ORDER_AFTER_BILL_PRINTED',
	CANCEL_ORDER_BEFORE_PAYMENT = 'CANCEL_ORDER_BEFORE_PAYMENT',
	CANCEL_ORDER_AFTER_PAYMENT = 'CANCEL_ORDER_AFTER_PAYMENT',
	CANCEL_RECEIPT = 'CANCEL_RECEIPT',
	AUTO_LOGOUT = 'AUTO_LOGOUT',
}

enum GENDER {
	MALE = 'male',
	FEMALE = 'female',
	OTHER = 'other',
}

enum ITEM_TYPE {
	FOOD = 'food',
	DRINK = 'drink',
}

enum LOCATION_TYPE {
	TABLE = 'table',
	PICK_UP_POINT = 'pick_up_point',
}

enum LOCATION_STATUS {
	OCCUPIED = 'occupied',
	OPEN = 'open',
}

enum GEOLOCATION_TYPE {
	LOCATION = 'location',
	CUSTOM = 'custom',
}

enum GEOMETRIC_SHAPE_TYPE {
	CIRCLE = 'circle',
	SQUARE = 'square',
	RECTANGLE = 'rectangle',
}

enum ITEM_STATUS {
	PREPARING = 'preparing',
	DELIVERED = 'delivered',
}

enum TAB_STATUS {
	OPEN = 'open',
	CLOSED = 'closed',
}

enum DISCOUNT_TYPE {
	AMOUNT = 'amount',
	PERCENTAGE = 'percentage',
}

enum FEE_CHARGING_METHODS {
	LINE_ITEM = 'line_item',
	BURIED_IN_MENU = 'buried_in_menu',
}

/** @deprecated don't need to use anymore until new print log type is needed */
enum PRINT_LOG_TYPE {
	TICKET = 'ticket',
	TAB_RECEIPT = 'tab-receipt',
	ORDER_RECEIPT = 'order-receipt',
	ORDER_INVOICE = 'order-invoice',
	TAB_INVOICE = 'tab-invoice',
}

enum ORDER_SOURCE {
	QR = 'qr',
	TILL = 'till',
}

enum USER_TITLES {
	MR = 'mr',
	MRS = 'mrs',
	MS = 'ms',
}

enum OFFER_TYPE {
	ITEMS = 'items',
	CATEGORIES = 'categories',
	ITEM_TYPES = 'item_types',
	RECEIPTS = 'receipts',
}

enum PRINT_TYPE {
	CHECKLIST = 'checklist',
	TICKET = 'ticket',
	RECEIPT = 'receipt',
	REPORT = 'report',
	CUSTOM = 'custom',
	CANCELLED_ORDER = 'cancelled_order',
	KITCHEN_MESSAGE = 'kitchen_message',
}

enum RECEIPT_TYPE_PREFIX {
	INTERNAL_RECEIPT = 'IR',
	EXTERNAL_RECEIPT = '',
}

enum TAG_TYPES {
	LOCATION_GROUP = 'location_group',
	PRINTER = 'printer',
}

enum DISCOUNT_EFFECTIVE_LEVEL {
	ITEM = 0,
	ORDER = 1,
	ORDER_GROUP = 2,
}

enum ACCOUNTING_JOURNAL_TRANSACTION_TYPE {
	RECEIVABLE = 'receivable',
	PAYABLE = 'payable',
}

enum CUSTOM_REPORT_DISPLAY_TYPE {
	DOGHNUT = 'doughnut',
	TABLE = 'table',
	// BAR = 'bar',
}

enum CUSTOM_REPORT_VALUE_FIELD {
	COUNT = 'count',
	SUB_TOTAL = 'subtotal',
	GROSS_SALES = 'grossSales',
	DISCOUNT = 'discount',
	OFFER = 'offer',
	SERVICE_CHARGE = 'serviceCharge',
	VAT = 'vat',
	ADJUSTMENT = 'adjustment',
	NET_SALES = 'netSales',
	CANCELLED_VALUE = 'cancelledValue',
}

enum CUSTOM_REPORT_VALUE_TYPE {
	ITEM_TYPE = 'itemType',
	ITEM = 'item',
	LOCATION = 'location',
	LOCATION_GROUP_TAG = 'locationGroupTag',
	OFFER = 'offer',
}

export {
	ACCOUNTING_JOURNAL_TRANSACTION_TYPE,
	DISCOUNT_EFFECTIVE_LEVEL,
	DISCOUNT_TYPE,
	EXCHANGE_RATE,
	FEE_CHARGING_METHODS,
	GENDER,
	GEOLOCATION_TYPE,
	GEOMETRIC_SHAPE_TYPE,
	ITEM_STATUS,
	ITEM_TYPE,
	LOCATION_STATUS,
	LOCATION_TYPE,
	OFFER_TYPE,
	ORDER_CHANGELOG_TYPE,
	ORDER_SOURCE,
	ORDER_STATUS,
	PAYMENT_TYPE,
	PAYMENT_TYPE_TITLE,
	PRINT_LOG_TYPE,
	PRINT_TYPE,
	RECEIPT_TYPE_PREFIX,
	TAB_STATUS,
	TAG_TYPES,
	USER_ROLE,
	USER_TITLES,
	USER_PERMISSIONS,
	CUSTOM_REPORT_DISPLAY_TYPE,
	CUSTOM_REPORT_VALUE_FIELD,
	CUSTOM_REPORT_VALUE_TYPE,
	OPS_PAGE,
};

/** @deprecated kept only for `api` usage, should be use the generated types from `hub` gql schema instead */
export enum UPLOAD_TYPES {
	ITEM = 'item',
	MENU = 'menu',
	VENUE_BANNER = 'venue_banner',
	VENUE_LOGO = 'venue_logo',
}
