import getSymbolFromCurrency from "@dcspark/currency-symbol-map";
import { ConfigType } from "dayjs";

import { dayjs } from "../libs/dayjs";
import { formatNumber } from "../misc";
import { CurrencyConfigInput } from "../convertCurrencies";

export interface VenueDataHelperParameter {
	currencies_configs: CurrencyConfigInput[];
}

export const venueDataHelper = (venue?: VenueDataHelperParameter) => {
	const getPrimaryCurrency = () => {
		return venue?.currencies_configs?.find(
			(c) => c.is_primary_currency === true,
		);
	};

	const getSecondaryCurrency = () => {
		return venue?.currencies_configs?.find(
			(c) => c.is_secondary_currency === true && !c.is_deactivated,
		);
	};

	const getSecondaryCurrencies = () => {
		return venue?.currencies_configs?.filter(
			(c) => c.is_secondary_currency === true && !c.is_deactivated,
		);
	};

	const getPrimaryCurrencySymbol = () => {
		const primaryCurrency = getPrimaryCurrency();
		return primaryCurrency ? getSymbolFromCurrency(primaryCurrency.code) : "�";
	};

	const getSecondaryCurrencySymbol = () => {
		const secondaryCurrency = getSecondaryCurrency();
		return secondaryCurrency
			? getSymbolFromCurrency(secondaryCurrency.code)
			: "�";
	};

	const $d = (amount: number) => {
		const symbol = getPrimaryCurrencySymbol();
		const absAmount = Math.abs(amount);

		return amount < 0
			? `- ${symbol} ${formatNumber(absAmount)}`
			: `${symbol} ${formatNumber(absAmount)}`;
	};

	const $d2n = (amount: number) => {
		const symbol = getSecondaryCurrencySymbol();
		const absAmount = Math.abs(amount);

		return amount < 0
			? `- ${symbol} ${formatNumber(absAmount)}`
			: `${symbol} ${formatNumber(absAmount)}`;
	};

	return {
		getPrimaryCurrency,
		getPrimaryCurrencySymbol,
		getSecondaryCurrency,
		getSecondaryCurrencySymbol,
		getSecondaryCurrencies,
		/** Display number with venue primary currency */
		$d,
		/** Display number with venue secondary currency (if any) */
		$d2n,
	};
};

export const toVenueTime = (
	utcTime: ConfigType,
	venue: { timezone: string },
	format = "DD/MM/YY, HH:mm a",
) => dayjs(utcTime).tz(venue.timezone).format(format);
