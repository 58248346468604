import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
interface PaymentOptionRadioOption {
  title: string;
  subtitle: string;
  value: string;
  icon: JSX.Element;
}
interface PaymentOptionsRadioGroupProps {
  title: string;
  options: PaymentOptionRadioOption[];
  value: string;
  onChange: (value: string) => void;
}
const PaymentOptionsRadioGroup = ({
  title,
  options,
  value,
  onChange
}: PaymentOptionsRadioGroupProps) => {
  return <Stack gap={{
    xs: 1,
    sm: 1.5
  }} data-sentry-element="Stack" data-sentry-component="PaymentOptionsRadioGroup" data-sentry-source-file="index.tsx">
			<Typography variant="body1" fontWeight={600} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
				{title}
			</Typography>

			<FormControl sx={{
      width: 1
    }} data-sentry-element="FormControl" data-sentry-source-file="index.tsx">
				<RadioGroup value={value} sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        gap: {
          xs: 1,
          sm: 1.5
        }
      }} onChange={(_e, value) => onChange(value)} data-sentry-element="RadioGroup" data-sentry-source-file="index.tsx">
					{options.map(({
          icon,
          value,
          title,
          subtitle
        }) => <FormControlLabel key={value} sx={{
          mr: 0
        }} value={value} control={<Radio sx={{
          pr: 1
        }} />} label={<Box display="flex" gap={{
          xs: 1,
          sm: 1.5
        }} alignItems="center">
									<Box width="48px" display="flex" justifyContent="center" alignItems="center">
										{icon}
									</Box>
									<Box display="flex" flexDirection="column" gap={{
            xs: 0.5,
            sm: 0.75
          }}>
										<Typography lineHeight={1} fontWeight={500} variant="body1">
											{title}
										</Typography>
										<Typography sx={() => ({
              color: "#697386",
              fontSize: "0.75rem",
              fontWeight: 400
            })} lineHeight={1}>
											{subtitle}
										</Typography>
									</Box>
								</Box>} />)}
				</RadioGroup>
			</FormControl>
		</Stack>;
};
export { PaymentOptionsRadioGroup };
export type { PaymentOptionRadioOption, PaymentOptionsRadioGroupProps };