export enum REDIS_PREFIX {
	CONSUMER_EVENT = "served:consumer:",
	NUDGE_TIMER = "served:consumer:post_order_nudge:",
	TEMP_ORDER = "served:consumer:temp_order:",
	EXISTING_ORDER = "served:consumer:existing_order:",
	PAYWAY_POSTBACK = "served:payway_postback:",
	CACHED_ROLE = "cached_role:",
	CACHED_CONSUMER = "cached_consumer:",
	CACHED_STAFF = "cached_staff:",
	CACHED_ADMIN = "cached_admin:",
	CACHED_VENUE = "cached_venue:",
}

export enum REDIS_EVENT {
	TEMP_ORDER = "temp_order",
}

export enum SOCKET_PREFIX {
	STAFF_ORDERS = "orders-venues:",
	STAFF_LOCATIONS = "locations-venues:",
	STAFF_GEOLOCATIONS = "geolocations-venues:",
	STAFF_PRINTER = "printer:",
	CONSUMER_ORDER = "consumer-order:",
	NOTIFICATION = "notification:",
	CONSUMER_VENUE = "consumer-venue:",
	CONSUMER_LOCATION = "consumer-location:",
	CONSUMER_MENU = "consumer-menu:",
}

export enum SOCKET_EVENT {
	NOTIFICATION_CREATED = "notification-created",
	ORDERS_LIST = "orders-list",
	ORDER_CREATED = "order-created",
	ORDER_UPDATED = "order-updated",
	LOCATIONS_LIST = "locations-list",
	LOCATION_CREATED = "location-created",
	LOCATION_UPDATED = "location-updated",
	LOCATION_DELETED = "location-deleted",
	GEOLOCATIONS_LIST = "geolocations-list",
	GEOLOCATION_CREATED = "geolocation-created",
	GEOLOCATION_UPDATED = "geolocation-updated",
	GEOLOCATION_DELETED = "geolocation-deleted",
	HEALTH_CHECK_INIT = "health-check-init",
	HEALTH_CHECK_SHUTDOWN = "health-check-shutdown",
	NEW_VERSION_AVAILABLE = "new-version-available",
	ORDER_STATUS_INIT = "order-status-init",
	PRINTER_PRINT_REQUEST = "print-request",
	PRINTER_PING = "ping",
	PRINTER_PONG = "pong",
	CONSUMER_ORDER_UPDATE = "consumer-order-update",
	CONSUMER_VENUE_UPDATED = "consumer-venue-updated",
	CONSUMER_LOCATION_UPDATED = "consumer-location-updated",
	CONSUMER_MENU_UPDATED = "consumer-menu-updated",
	CONSUMER_ORDER_PAYMENT_CONFIRMED = "consumer-order-payment-confirmed",
}
