import { useRouter } from "next/router";
import { useEffect } from "react";

import { useGetActiveMenuForQr } from "~/queries/useGetActiveMenuForQr";
import { useGetCurrentDevice } from "~/queries/useGetCurrentDevice";
import { useGetCurrentLocation } from "~/queries/useGetCurrentLocation";
import { useGetCurrentVenue } from "~/queries/useGetCurrentVenue";
import { useGetOrder } from "~/queries/useGetOrder";
import { setState, useLocationId, useVenueId } from "~/store";
import { State } from "~/types/store";
import { getPayloadFromToken } from "~/utils/getPayloadFromToken";

const useInitApp = () => {
	const router = useRouter();

	const locationId = useLocationId();
	const venueId = useVenueId();

	useGetCurrentDevice();
	useGetOrder();
	useGetCurrentVenue(!!venueId);
	useGetActiveMenuForQr(!!locationId);
	useGetCurrentLocation(!!locationId);

	useEffect(() => {
		async function start() {
			const obj = {} as State;
			if (router.query.token) {
				obj.token = router.query.token as string;
				const { venueId, locationId } = getPayloadFromToken(obj.token);
				obj.venueId = venueId;
				obj.locationId = locationId;
			}
			if (router.pathname === "/thank-you" && router.query.ref)
				obj.orderRef = router.query.ref as string;
			else obj.orderRef = null;
			if (router.pathname === "/thank-you" && router.query.id)
				obj.orderId = router.query.id as string;
			else obj.orderId = null;

			if (
				(router.pathname === "/menu" || router.pathname === "/cart") &&
				!router.query.token
			) {
				await router.replace(
					"/errors?title=OOPS! Ordering not allowed&description=Make sure you scan and follow the QR you can find at one our partners' location.",
				);
			}

			setState({ ...obj });
		}

		if (router.pathname === "/" || router.pathname === "/thank-you")
			setState({ isReady: true });
		if (router.pathname !== "/" && Object.keys(router.query).length) {
			void start();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.query.token, router.query.ref, router.query.id]);
};

export { useInitApp };
