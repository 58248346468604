import { AppProps } from "next/app";
import Router from "next/router";
import { useEffect } from "react";
import { useBoolean, useEffectOnce, useEventListener } from "usehooks-ts";
import { ErrorBoundary, SplashScreen } from "~served/ui-comps";
import { AppUpdateChecker } from "~/components/AppUpdateChecker";
import { AssistanceDialog } from "~/components/AssistanceDialog";
import { ErrorAlert } from "~/components/ErrorAlert";
import { InfoAlert } from "~/components/InfoAlert";
import { OfflineDialog } from "~/components/OfflineDialog";
import { PushNotification } from "~/components/PushNotification";
import { SessionTrackerDialog } from "~/components/SessionTrackerDialog";
import { useRouterEvent } from "~/libs/google-analytics";
import { AppProvider } from "~/providers/AppProvider";
import { QueryProvider } from "~/providers/QueryProvider";
import "~/store";
import { useIsReady, useResetApp } from "~/store";
export default function MyApp({
  Component,
  pageProps
}: AppProps) {
  const {
    value: isMounted,
    setTrue: setMounted
  } = useBoolean(false);
  const resetApp = useResetApp();
  useEffectOnce(() => {
    setMounted();
  });
  useEventListener("unload", () => {
    resetApp();
    window.$queryClient?.clear?.();
  });
  useEffect(() => {
    const onReceiveServiceWorkerMessage = (event: MessageEvent) => {
      if (!event.data.action) return;
      switch (event.data.action) {
        case "redirect-from-notificationclick":
          void Router.replace(event.data.url);
          break;
      }
    };
    if (navigator.serviceWorker) navigator.serviceWorker.addEventListener("message", onReceiveServiceWorkerMessage);
    return () => {
      if (navigator.serviceWorker) navigator.serviceWorker.removeEventListener("message", onReceiveServiceWorkerMessage);
    };
  }, []);
  useRouterEvent();
  const isReady = useIsReady();
  if (!isMounted) return null;
  return <ErrorBoundary appName="CONSUMER APP" id="1239414865336991766" token="i8lKTnR7kUSCmzu2PpX56oiSFOnN6gfG_KpeCTbYP-Oz2OYZXyxoEBBKQ7YtUV3PE7-_" data-sentry-element="ErrorBoundary" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
			<QueryProvider data-sentry-element="QueryProvider" data-sentry-source-file="_app.tsx">
				<AppProvider data-sentry-element="AppProvider" data-sentry-source-file="_app.tsx">
					<OfflineDialog data-sentry-element="OfflineDialog" data-sentry-source-file="_app.tsx" />
					<AssistanceDialog data-sentry-element="AssistanceDialog" data-sentry-source-file="_app.tsx" />
					<InfoAlert data-sentry-element="InfoAlert" data-sentry-source-file="_app.tsx" />
					<ErrorAlert data-sentry-element="ErrorAlert" data-sentry-source-file="_app.tsx" />
					<SessionTrackerDialog data-sentry-element="SessionTrackerDialog" data-sentry-source-file="_app.tsx" />
					<AppUpdateChecker data-sentry-element="AppUpdateChecker" data-sentry-source-file="_app.tsx" />
					<PushNotification data-sentry-element="PushNotification" data-sentry-source-file="_app.tsx" />

					{isReady ? <Component {...pageProps} /> : <SplashScreen />}
				</AppProvider>
			</QueryProvider>
		</ErrorBoundary>;
}