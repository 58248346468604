import { Client } from "graphql-ws";

import { SubscribeToOrderPaymentTypeAcceptEventSubscription } from "~/types/__generated/gql/graphql";
import { getOrderQueryKey } from "../useGetOrder";

const query = /* GraphQL */ `
	subscription SubscribeToOrderPaymentTypeAcceptEvent($id: ObjectID!) {
		subscribeToOrderPaymentTypeAcceptEvent(id: $id) {
			id
		}
	}
`;

export const subscribeToOrderPaymentTypeAcceptEvent = async (
	client: Client,
	orderId: string,
) => {
	const subscription =
		client.iterate<SubscribeToOrderPaymentTypeAcceptEventSubscription>({
			query,
			variables: { id: orderId },
		});
	for await (const result of subscription) {
		if (result.data?.subscribeToOrderPaymentTypeAcceptEvent)
			window.$queryClient?.setQueryData(getOrderQueryKey(orderId), (prev) =>
				prev
					? { ...prev, $paymentTypeAccepted: true }
					: { $paymentTypeAccepted: true },
			);
	}
};
