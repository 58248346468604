import { Styled } from "./HueSlider.styled";
import type { SliderProps } from "@mui/material/Slider";
type HueSliderProps = SliderProps;
const HueSlider = (props: HueSliderProps) => {
  const {
    className,
    ...restSliderProps
  } = props;
  return <Styled.Slider className={`MuiColorInput-HueSlider ${className ?? ""}`} {...restSliderProps} data-sentry-element="unknown" data-sentry-component="HueSlider" data-sentry-source-file="HueSlider.tsx" />;
};
export default HueSlider;