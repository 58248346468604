import Big from "big.js";

import {
	CreateOrderItemSubtotalAddOnsDiscountInput,
	DISCOUNT_TYPE,
} from "~served/types/gql";

export type DiscountInput = Pick<
	CreateOrderItemSubtotalAddOnsDiscountInput,
	"type" | "value"
>;

export function getDiscountAmount(amount: number, discount?: DiscountInput) {
	let discountAmount = 0;

	if (!discount?.type || !discount?.value) {
		discountAmount = 0;
	} else {
		if (discount?.type === DISCOUNT_TYPE.amount) {
			discountAmount = discount.value;
		}
		if (discount?.type === DISCOUNT_TYPE.percentage)
			discountAmount = new Big(amount)
				.mul(discount.value)
				.div(100)
				.round(2)
				.toNumber();
	}

	const discountedAmount = new Big(amount)
		.minus(discountAmount)
		.round(2)
		.toNumber();

	return {
		discountAmount,
		/** @deprecated for naming convention, use `discountedAmount` instead */
		amountAfterDiscount: discountedAmount,
		discountedAmount,
	};
}
