import { html } from "common-tags";

import { UndefinedOrNull } from "~served/types/utils";

import { OrderLike } from "../calculation";
import locales from "../locales/locales.json";
import { GetFullNameParameters, getFullName } from "../misc";
import { toVenueTime } from "../venue";

import {
	GetStyleFragmentParameters,
	getStyleFragment,
} from "./fragments/get-style-fragment";

interface GenerateKitchenMessageTemplateArgs {
	language?: string | null;
	venue: GetStyleFragmentParameters & { timezone: string };
	location: { name: string };
	message: string;
	orders?: UndefinedOrNull<OrderLike[]>;
	staff?: UndefinedOrNull<GetFullNameParameters>;
}

export const generateKitchenMessageTemplate = ({
	language,
	venue,
	orders,
	location,
	staff,
	message,
}: GenerateKitchenMessageTemplateArgs) => {
	const localizedText = language ? locales[language] : locales.en;
	return html`
		<html>
			<head>
				${getStyleFragment(venue, "ticket")}
			</head>
			<body>
				<div class="container">
					${orders?.length
						? `<div class="text-xl light-bold my-1 centered">${orders
								.map((o) => `#${o.index}`)
								.join(", ")}</div>`
						: ""}
					<div class="text-xl light-bold my-1 centered">${location.name}</div>

					<hr class="divider" />

					${staff
						? `<div class="text-md my-1">${localizedText.staff}: ${getFullName(
								staff,
							)}</div>`
						: ""}
					<div class="text-md my-1">
						${localizedText.received}: ${toVenueTime(new Date(), venue)}
					</div>

					<hr class="divider" />

					<div class="text-md my-1">${localizedText.message}:</div>
					<div class="text-lg light-bold my-1">${message}</div>
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, "")
		.replace(/>\s*</g, "><")
		.trim();
};
