import { UpdateCurrentVenueCurrenciesConfigInput } from "~served/types/gql";

import { bigMath } from "../misc";

export type CurrencyConfigInput = UpdateCurrentVenueCurrenciesConfigInput;

export function convertCurrencies<T extends string>(
	baseValue: number,
	baseCurrency: T,
	currenciesConfigs: CurrencyConfigInput[],
): Record<T, number> {
	if (!currenciesConfigs.length)
		throw new Error("No configs currencies to work with");
	if (currenciesConfigs.findIndex((c) => c.code === baseCurrency) === -1)
		throw new Error(
			"Base currency is not valid or base currency does not exist in configured currencies",
		);

	return Object.fromEntries(
		currenciesConfigs.map((c) => [c.code, bigMath.mul(baseValue, c.rate)]),
	) as Record<T, number>;
}
