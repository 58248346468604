import { ObjectID } from 'bson';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { pick } from 'lodash';

import { OrderItemLike, applyServiceChargeToOrder, applyVatToOrder } from '~served/utils';

import { getCurrentLocationCache } from '~/queries/useGetCurrentLocation';
import { getCurrentVenueCache } from '~/queries/useGetCurrentVenue';
import { Checkout_With_Pay_Way_Payment_Option, CreateOrderItemInput } from '~/types/__generated/gql/graphql';
import { Actions, State } from '~/types/store';
import { createSelectors } from '~/types/utils';
const INITIAL_STATE: State = {
	error: null,
	info: null,
	isSessionExpired: false,
	isReady: false,
	isConnectedToServer: true,
	isLatest: true,
	isNeededHelp: false,
	token: null,
	venueId: null,
	locationId: null,
	menuId: null,
	orderRef: null,
	orderId: null,
	deviceId: null,
	cart: {
		customer: undefined,
		payment_types: [],
		items: [],
	},
	paywayPaymentType: Checkout_With_Pay_Way_Payment_Option.Abapay,
};

const _useStore = create<State & Actions>()(
	persist(
		(set, get) => ({
			...INITIAL_STATE,
			setIsSessionExpired: (data) => set({ isSessionExpired: data }),
			setError: (data) => set({ error: data }),
			setInfo: (data) => set({ info: data }),
			setIsReady: (data) => set({ isReady: data }),
			setIsConnectedToServer: (data) => set({ isConnectedToServer: data }),
			setIsLatest: (data) => set({ isLatest: data }),
			setIsNeededHelp: (data) => set({ isNeededHelp: data }),
			setDeviceId: (data) => set({ deviceId: data }),
			updateCart: (data) => {
				const newCart = { ...get().cart, ...data };

				const venue = getCurrentVenueCache();
				const location = getCurrentLocationCache();
				if (!location || !venue) throw new Error('Invalid venue or location data');

				const vat = location.no_vat ? 0 : venue.vat;
				const serviceCharge = location.no_service_charge ? 0 : venue.service_charge;

				const { items: itemsWithVat } = applyVatToOrder({
					order: { items: newCart.items },
					vat,
				});
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});
				newCart.items = itemsWithServiceCharge as CreateOrderItemInput[];

				set({ cart: newCart });
			},
			addItem: (item) => {
				const prevItems = get().cart.items;
				const itemInCart = prevItems.find((ci) => ci.item === item._id && !ci.options.length);

				const newItems = itemInCart
					? prevItems.map((ci) =>
							ci._id === itemInCart._id ? { ...itemInCart, quantity: itemInCart.quantity + 1 } : ci
					  )
					: [
							...prevItems,
							{
								...pick(item, [
									'item_id',
									'title',
									'prep_time',
									'type',
									'original_price',
									'minimum_required_price',
									'original_price_addons',
									'listed_price',
									'extra_quantity',
									'category',
									'printer_tag',
									'no_vat',
									'no_service_charge',
								]),
								_id: new ObjectID().toString(),
								item: item._id,
								recipe: item.recipe,
								quantity: 1,
								options: [],
							} as OrderItemLike,
					  ];

				const venue = getCurrentVenueCache();
				const location = getCurrentLocationCache();

				if (!location || !venue) throw new Error('Invalid venue or location data');

				const vat = location.no_vat ? 0 : venue.vat;
				const serviceCharge = location.no_service_charge ? 0 : venue.service_charge;

				const { items: itemsWithVat } = applyVatToOrder({
					order: { items: newItems },
					vat,
				});
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});
				set((s) => ({ cart: { ...s.cart, items: itemsWithServiceCharge as CreateOrderItemInput[] } }));
			},
			removeItem: (item) => {
				const prevItems = get().cart.items;
				const itemInCart =
					prevItems.find((ci) => ci.item === item._id && !ci.options.length) ||
					prevItems.find((ci) => ci.item === item._id);

				if (!itemInCart) return;

				const newItems = prevItems
					.map((ci) =>
						ci._id === itemInCart._id ? { ...itemInCart, quantity: itemInCart.quantity - 1 } : ci
					)
					.filter((i) => i.quantity);

				const venue = getCurrentVenueCache();
				const location = getCurrentLocationCache();
				if (!location || !venue) throw new Error('Invalid venue or location data');

				const vat = location.no_vat ? 0 : venue.vat;
				const serviceCharge = location.no_service_charge ? 0 : venue.service_charge;

				const { items: itemsWithVat } = applyVatToOrder({
					order: { items: newItems },
					vat,
				});
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});
				set((s) => ({ cart: { ...s.cart, items: itemsWithServiceCharge as CreateOrderItemInput[] } }));
			},
			setPaywayPaymentType: (data) => set({ paywayPaymentType: data }),
			resetCart: () => set({ cart: INITIAL_STATE.cart }),
			resetApp: () => set(INITIAL_STATE),
		}),
		{
			name: 'served-consumer-app-store',
			storage: createJSONStorage(() => window.sessionStorage),
		}
	)
);

const { use, setState, getState } = createSelectors(_useStore);

const useError = () => {
	return use.error();
};
const useSetError = () => {
	return use.setError();
};
const useInfo = () => {
	return use.info();
};
const useSetInfo = () => {
	return use.setInfo();
};
const useIsReady = () => {
	return use.isReady();
};
const useSetIsReady = () => {
	return use.setIsReady();
};
const useIsConnectedToServer = () => {
	return use.isConnectedToServer();
};
const useSetIsConnectedToServer = () => {
	return use.setIsConnectedToServer();
};
const useIsLatest = () => {
	return use.isLatest();
};
const useSetIsLatest = () => {
	return use.setIsLatest();
};
const useIsNeededHelp = () => {
	return use.isNeededHelp();
};
const useSetIsNeededHelp = () => {
	return use.setIsNeededHelp();
};
const useToken = () => {
	return use.token();
};
const useVenueId = () => {
	return use.venueId();
};
const useLocationId = () => {
	return use.locationId();
};
const useMenuId = () => {
	return use.menuId();
};
const useOrderRef = () => {
	return use.orderRef();
};
const useOrderId = () => {
	return use.orderId();
};
const useDeviceId = () => {
	return use.deviceId();
};
const useSetDeviceId = () => {
	return use.setDeviceId();
};
const useUpdateCart = () => {
	return use.updateCart();
};
const useAddItem = () => {
	return use.addItem();
};
const useRemoveItem = () => {
	return use.removeItem();
};
const useCart = () => {
	return use.cart();
};
const useResetCart = () => {
	return use.resetCart();
};
const usePaywayPaymentType = () => {
	return use.paywayPaymentType();
};
const useSetPaywayPaymentType = () => {
	return use.setPaywayPaymentType();
};
const useSetIsSessionExpired = () => {
	return use.setIsSessionExpired();
};
const useIsSessionExpired = () => {
	return use.isSessionExpired();
};
const useResetApp = () => {
	return use.resetApp();
};

export {
	getState,
	setState,
	useAddItem,
	useCart,
	useDeviceId,
	useError,
	useInfo,
	useIsConnectedToServer,
	useIsLatest,
	useIsNeededHelp,
	useIsReady,
	useIsSessionExpired,
	useLocationId,
	useMenuId,
	useOrderId,
	useOrderRef,
	usePaywayPaymentType,
	useRemoveItem,
	useResetApp,
	useResetCart,
	useSetDeviceId,
	useSetError,
	useSetInfo,
	useSetIsConnectedToServer,
	useSetIsLatest,
	useSetIsNeededHelp,
	useSetIsReady,
	useSetIsSessionExpired,
	useSetPaywayPaymentType,
	useToken,
	useUpdateCart,
	useVenueId,
};
