import { html } from "common-tags";

import { UndefinedOrNull } from "~served/types/utils";

// import { OrderItemLike } from '../calculation';
import locales from "../locales/locales.json";
import { GetFullNameParameters, getFullName } from "../misc";
import { toVenueTime } from "../venue";
import { OrderItemLike, OrderLike } from "../calculation";
import { orderDataHelper } from "../order";

import {
	GetStyleFragmentParameters,
	getStyleFragment,
} from "./fragments/get-style-fragment";

interface generateOptionKitchenMessageTemplateArgs {
	language?: string | null;
	venue: GetStyleFragmentParameters & { timezone: string };
	location: { name: string };
	orderNumber: string;
	message: string;
	order: OrderLike;
	item: OrderItemLike;
	staff?: UndefinedOrNull<GetFullNameParameters>;
}

export const generateOptionKitchenMessageTemplate = ({
	language,
	venue,
	order,
	item,
	location,
	staff,
	orderNumber,
	message,
}: generateOptionKitchenMessageTemplateArgs) => {
	if (!item) throw new Error("No items to print");
	const { $d } = orderDataHelper(order);
	const localizedText = language ? locales[language] : locales.en;
	return html`
		<html>
			<head>
				${getStyleFragment(venue, "ticket")}
			</head>
			<body>
				<div class="container">
					<div class="text-xl light-bold my-1 centered">#${orderNumber}</div>
					<div class="text-xl light-bold my-1 centered">${location.name}</div>

					<hr class="divider" />

					${staff
						? `<div class="text-md my-1">${localizedText.staff}: ${getFullName(
								staff,
							)}</div>`
						: ""}
					<div class="text-md my-1">
						${localizedText.received}: ${toVenueTime(new Date(), venue)}
					</div>

					<hr class="divider" />

					${item.title
						.trim()
						.split("  ")
						.map(
							(chunk, i) => `
										<div class="row my-1">
											<div class="col-qty-ticket">${i === 0 ? `${item.quantity}x` : ""}</div>
											<div class="col-item-ticket">${chunk}</div>
											<div class="col-price">${$d(item.subtotal)}</div>
										</div>
									`,
						)
						.join("\n")}
					${item.comments
						? `
											<div class="row my-1">
												<div class="col-qty-ticket"></div>
												<div class="col-item-ticket italic light-bold">"${item.comments}"</div>
											</div>
										`
						: ""}
					${item.options?.length
						? item.options
								.map(
									(o) => `
													<div class="row my-1">
														<div class="col-qty-ticket"></div>
														<div class="col-item-ticket">+ ${o.title}</div>
													</div>

													${
														o.comments
															? `
																<div class="row my-1">
																	<div class="col-qty-ticket"></div>
																	<div class="col-item-ticket italic light-bold">"${o.comments}"</div>
																</div>
															`
															: ""
													}
												`,
								)
								.join("\n")
						: ""}
					<div class="row my-1">
						<div class="col-qty-ticket"></div>
						<div class="col-item-ticket italic light-bold">
							${localizedText.message}:
							<div>${message}</div>
						</div>
					</div>
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, "")
		.replace(/>\s*</g, "><")
		.trim();
};
