import { html } from "common-tags";

import {
	QuerygetPrintableReportsItemsArgs,
	SalesSummaryByCategories,
} from "~served/types/gql";

import locales from "../locales/locales.json";
import { bigMath, formatNumber } from "../misc";
import {
	VenueDataHelperParameter,
	toVenueTime,
	venueDataHelper,
} from "../venue";

import {
	GetStyleFragmentParameters,
	getStyleFragment,
} from "./fragments/get-style-fragment";

type GenerateItemsReportsOpts = Pick<
	QuerygetPrintableReportsItemsArgs,
	"from" | "to" | "shift"
> & {
	categoriesSummary: SalesSummaryByCategories;
	onlyItemsWithSales: boolean;
	venue: GetStyleFragmentParameters &
		VenueDataHelperParameter & {
			timezone: string;
		};
	language: string;
	staff_name: string;
	printed_time: string;
};

const generateItemsReportsTemplate = ({
	from,
	to,
	shift,
	categoriesSummary,
	onlyItemsWithSales,
	venue,
	language,
	staff_name,
	printed_time,
}: GenerateItemsReportsOpts) => {
	const sortedSummaries = categoriesSummary.byCategories
		.filter((i) => (onlyItemsWithSales ? !!i.count : true))
		.sort((a, b) => b.grossSales - a.grossSales)
		.map((c) => ({
			...c,
			byItems: c.byItems
				.filter((i) => (onlyItemsWithSales ? !!i.count : true))
				.sort((a, b) => b.grossSales - a.grossSales),
		}));

	const { $d } = venueDataHelper(venue);
	const localizedText = language ? locales[language] : locales.en;

	return html`
		<html>
			<head>
				${getStyleFragment(venue, "reports")}
			</head>
			<body>
				<div class="container">
					<div class="centered text-xl light-bold my-1">
						${localizedText.salesBreakdownReport}
					</div>

					<br />
					<div class="text-sm my-1">${localizedText.staff}: ${staff_name}</div>
					<div class="text-sm my-1">
						${localizedText.printedTime}: ${toVenueTime(printed_time, venue)}
					</div>
					<div class="text-sm my-1">
						${localizedText.from}: ${toVenueTime(from, venue)},
						${localizedText.to}: ${toVenueTime(to, venue)}
					</div>

					${shift
						? `
                <div class="text-sm my-1">
                  Shift: ${shift}
                </div>
              `
						: ""}

					<br />

					<div class="centered text-md">
						${localizedText.salesGroupByCategories}
					</div>
					<hr class="divider" />

					${sortedSummaries
						.map(
							(cSummary) => `
                <div class="row text-sm">
                  <div class="col-count-reports">${cSummary.count}</div>
                  <div class="col-title-reports">${
										cSummary._category.title
									}</div>
                  <div class="col-revenue-reports text-align-right">${$d(
										cSummary.grossSales,
									)}</div>
                  <div class="col-revenue-percentage-reports text-align-right">
                    ${
											formatNumber(
												bigMath.div(
													cSummary.grossSales,
													categoriesSummary.grossSales,
												),
											) || "0.00"
										}%
                  </div>
                </div>
              `,
						)
						.join("\n")}
					<div class="row text-sm">
						<div class="col-count-reports">${categoriesSummary.count}</div>
						<div class="col-title-reports">${localizedText.total}</div>
						<div class="col-revenue-reports text-align-right">
							${$d(categoriesSummary.grossSales)}
						</div>
						<div class="col-revenue-percentage-reports text-align-right">
							100.00%
						</div>
					</div>

					<br />

					${sortedSummaries
						.map(
							(cSummary) => `
                <div class="centered text-lg mt-1">${`${cSummary._category.title} ${localizedText.sales}`.toUpperCase()}</div>
                <hr class="divider" />

                ${cSummary.byItems
									.map(
										(iSummary) => `
                      <div class="row text-sm">
                        <div class="col-count-reports">${iSummary.count}</div>
                        <div class="col-title-reports">${
													iSummary._item.title
												}</div>
                        <div class="col-revenue-reports text-align-right">${$d(
													iSummary.grossSales,
												)}</div>
                        <div class="col-revenue-percentage-reports text-align-right">
                          ${
														formatNumber(
															bigMath.div(
																iSummary.grossSales,
																categoriesSummary.grossSales,
															),
														) || "0.00"
													}%
                        </div>
                      </div>
                    `,
									)
									.join("\n")}
              `,
						)
						.join("\n")}
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, "")
		.replace(/>\s*</g, "><")
		.trim();
};

export { generateItemsReportsTemplate };
