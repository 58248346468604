import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { hubGqlClient } from "~/libs/gql";
import { browserStorage } from "~/libs/localforage";
import { useDeviceId, useSetDeviceId } from "~/store";
import { graphql } from "~/types/__generated/gql";

const queryGetCurrentDevice = /* GraphQL */ `
	query GetCurrentDevice {
		getCurrentDevice {
			_id
			created_at
			updated_at
			fcm_tokens
			_active_orders {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				user
				customer
				venue
				status
				is_paid
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				is_request_payment_confirmation

				_location {
					_id
					name
				}
				_receipt {
					_id
					created_at
					updated_at
					payment_types {
						payment_type
						code
						amount
					}
				}
			}
		}
	}
`;
const queryCreateNewDevice = /* GraphQL */ `
	mutation CreateNewDevice {
		createNewDevice {
			_id
			created_at
			updated_at
			fcm_tokens
			_active_orders {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				user
				customer
				venue
				status
				is_paid

				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				is_request_payment_confirmation

				_location {
					_id
					name
				}
				_receipt {
					_id
					created_at
					updated_at
					payment_types {
						payment_type
						code
						amount
					}
				}
			}
		}
	}
`;

export const getCurrentDeviceQueryKey = () => ["device"];

export const useGetCurrentDevice = (enabled?) => {
	const [ready, setReady] = useState(false);
	const deviceId = useDeviceId();
	const setDeviceId = useSetDeviceId();

	useEffect(() => {
		async function start() {
			const cachedDeviceId = await browserStorage.deviceId.get();
			if (cachedDeviceId) setDeviceId(cachedDeviceId);
			setReady(true);
		}

		void start();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return useQuery({
		enabled: (enabled ?? deviceId) ? true : ready,
		queryKey: getCurrentDeviceQueryKey(),
		queryFn: () =>
			deviceId
				? hubGqlClient
						.request(graphql(queryGetCurrentDevice))
						.then((res) => res.getCurrentDevice)
						.catch(() =>
							hubGqlClient
								.request(graphql(queryCreateNewDevice), undefined, {})
								.then((res) => res.createNewDevice),
						)
				: hubGqlClient
						.request(graphql(queryCreateNewDevice))
						.then((res) => res.createNewDevice),
		onSuccess: async (data) => {
			if (!data) return;

			await browserStorage.deviceId.set(data._id);
			setDeviceId(data._id);
		},
	});
};

export const useGetCurrentDeviceCache = () => {
	return useGetCurrentDevice(false);
};
