import { UndefinedOrNull } from "~served/types/utils";
import { Offer } from "~served/types/db";

import { MongoDocument, OrderItemLike } from "../../calculation";
import { bigMath } from "../../misc";

export const getCombinedNotes = (
	orders: { note?: UndefinedOrNull<string> }[],
) =>
	orders
		.map((o) => o.note)
		.filter(Boolean)
		.join(", ");

export const getCombinedOffers = (items: OrderItemLike[]) =>
	items.reduce(
		(pre, cur) => {
			if (
				cur.subtotal_addons?.offer?.amount &&
				cur.subtotal_addons.offer.metadata?._id
			) {
				const newArray = [...pre];
				const offerIndex = newArray.findIndex(
					(p) =>
						p.metadata._id.toString() ===
						(cur.subtotal_addons!.offer.metadata as Offer)._id.toString(),
				);
				if (offerIndex !== -1) {
					newArray[offerIndex].amount = bigMath.add(
						newArray[offerIndex].amount,
						cur.subtotal_addons.offer.amount,
					);
				} else {
					newArray.push({
						metadata: cur.subtotal_addons.offer.metadata,
						amount: cur.subtotal_addons.offer.amount,
					});
				}

				return newArray;
			}

			return pre;
		},
		[] as { metadata: MongoDocument; amount: number }[],
	);

export const getTicketLinebreaksIndexDictionary: (obj: {
	ticket_linebreaks: number[];
}) => Record<number, number> = (obj) =>
	obj.ticket_linebreaks.reduce(
		(acc, linebreak) => ({ ...acc, [linebreak]: linebreak }),
		{},
	);
