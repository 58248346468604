import { html } from "common-tags";

import { UndefinedOrNull } from "~served/types/utils";

import { OrderLike } from "../calculation";
import locales from "../locales/locales.json";
import { GetFullNameParameters, getFullName } from "../misc";
import { toVenueTime } from "../venue";
import { orderDataHelper } from "../order";

import {
	GetStyleFragmentParameters,
	getStyleFragment,
} from "./fragments/get-style-fragment";
import { GenerateInvoiceTemplateParameters } from "./invoice";
import { getCombinedNotes, getTicketLinebreaksIndexDictionary } from "./utils";

interface GenerateTicketTemplateParametersVenue {
	timezone: string;
	custom_note_instructions?: UndefinedOrNull<string>;
}

type GenerateTicketTemplateParametersOrder = OrderLike & {
	note?: UndefinedOrNull<string>;
	ticket_linebreaks: number[];
};

export interface GenerateTicketTemplateParameters {
	language?: string | null;
	orders: GenerateTicketTemplateParametersOrder[];
	location: GenerateInvoiceTemplateParameters["location"];
	staff?: UndefinedOrNull<GetFullNameParameters>;
	venue: GetStyleFragmentParameters & GenerateTicketTemplateParametersVenue;
	useCancelledItems?: UndefinedOrNull<boolean>;
	headerTitle?: UndefinedOrNull<string>;
}

const generateTicketTemplate = ({
	language,
	orders,
	location,
	staff,
	venue,
	useCancelledItems: isCancelled,
	headerTitle,
}: GenerateTicketTemplateParameters) => {
	const allItems = orders
		.map((o) => (isCancelled ? (o.cancelled_items ?? []) : o.items))
		.flat();
	if (!allItems.length) throw new Error("No items to print");
	const refOrder = orders[0];
	const { $d } = orderDataHelper(refOrder);

	const staffToDisplay = staff ? getFullName(staff) : "-";
	const customNoteInstruction = venue.custom_note_instructions ?? "Note";
	const combinedNotes = getCombinedNotes(orders);
	const linebreaksKeyedByIndex = getTicketLinebreaksIndexDictionary(orders[0]);

	const localizedText = language ? locales[language] : locales.en;

	return html`
		<html>
			<head>
				${getStyleFragment(venue, "ticket")}
			</head>
			<body>
				<div class="container">
					${isCancelled
						? '<div class="text-2xl light-bold my-1 centered">CANCELLED</div>'
						: ""}
					${headerTitle
						? `<div class="text-xl light-bold my-1 centered">${
								localizedText[headerTitle]
									? localizedText[headerTitle]
									: headerTitle
							}</div>`
						: ""}
					<div class="text-xl light-bold my-1 centered">
						${orders.map((o) => `#${o.index}`).join(", ")}
					</div>
					<div class="text-xl light-bold my-1 centered">${location.name}</div>
					${combinedNotes
						? `<div class="text-sm my-1">${customNoteInstruction}: ${combinedNotes}</div>`
						: ""}

					<hr class="divider" />

					<div class="text-md my-1">
						${localizedText.staff}: ${staffToDisplay}
					</div>
					<div class="text-md my-1">
						${localizedText.received}: ${toVenueTime(new Date(), venue)}
					</div>

					<hr class="divider" />

					${allItems
						.map(
							(item, index) => `
							${item.title
								.trim()
								.split("  ")
								.map(
									(chunk, i) => `
										<div class="row my-1">
											<div class="col-qty-ticket">${i === 0 ? `${item.quantity}x` : ""}</div>
											<div class="col-item-ticket">${chunk}</div>
											<div class="col-price">${$d(item.subtotal)}</div>
										</div>
									`,
								)
								.join("\n")}

								${
									item.comments
										? `
											<div class="row my-1">
												<div class="col-qty-ticket"></div>
												<div class="col-item-ticket italic light-bold">"${item.comments}"</div>
											</div>
										`
										: ""
								}

							${
								item.options?.length
									? item.options
											.map(
												(o) => `
													<div class="row my-1">
														<div class="col-qty-ticket"></div>
														<div class="col-item-ticket">+ ${o.quantity}x ${o.title}</div>
													</div>

													${
														o.comments
															? `
																<div class="row my-1">
																	<div class="col-qty-ticket"></div>
																	<div class="col-item-ticket italic light-bold">"${o.comments}"</div>
																</div>
															`
															: ""
													}
												`,
											)
											.join("\n")
									: ""
							}

						${
							linebreaksKeyedByIndex[index] !== undefined &&
							index < allItems.length - 1
								? '<hr class="ticket-linebreak my-1" />'
								: ""
						}
					`,
						)
						.join("\n")}
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, "")
		.replace(/>\s*</g, "><")
		.trim();
};

export { generateTicketTemplate };
