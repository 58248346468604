import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const TextFieldStyled = styled(TextField)`
	input {
		text-align: center;
	}
`;

export default {
	TextFieldStyled,
};
