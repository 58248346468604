import { LoadingButton } from "@mui/lab";
import { Box, DialogActions, DialogContent, DialogProps, DialogTitle, Dialog as MuiDialog, Stack } from "@mui/material";
import type { LoadingButtonProps } from "@mui/lab";
import type { FC, ReactNode } from "react";
export const Dialog: FC<{
  title?: ReactNode;
  content?: ReactNode;
  BeforeContent?: ReactNode | null;
  AfterContent?: ReactNode | null;
  secondaryActionButtonProps?: LoadingButtonProps;
  cancelButtonProps?: Omit<LoadingButtonProps, "variant" | "color">;
  actionButtonProps?: Omit<LoadingButtonProps, "variant">;
  dialogProps?: Omit<DialogProps, "open" | "scroll" | "fullWidth">;
}> = ({
  title,
  content,
  secondaryActionButtonProps,
  cancelButtonProps,
  actionButtonProps,
  BeforeContent = null,
  AfterContent = null,
  dialogProps
}) => {
  return <MuiDialog maxWidth="lg" fullWidth open scroll="paper" {...dialogProps} data-sentry-element="MuiDialog" data-sentry-component="Dialog" data-sentry-source-file="index.tsx">
			{!!title && <DialogTitle>{title}</DialogTitle>}
			{!!BeforeContent && <Box sx={{
      px: 3
    }}>{BeforeContent}</Box>}
			{!!content && <DialogContent>{content}</DialogContent>}
			{!!AfterContent && <Box sx={{
      px: 3
    }}>{AfterContent}</Box>}
			{(!!cancelButtonProps || !!actionButtonProps || !!secondaryActionButtonProps) && <DialogActions sx={{
      px: 3,
      py: 2
    }}>
					<Stack flexGrow={1} direction="row" alignItems="center" gap={2} justifyContent={secondaryActionButtonProps ? "space-between" : "flex-end"}>
						{!!secondaryActionButtonProps && <LoadingButton {...secondaryActionButtonProps}>
								{secondaryActionButtonProps.children}
							</LoadingButton>}
						<Stack direction="row" gap={2} flexGrow={secondaryActionButtonProps ? 0 : 1}>
							{!!cancelButtonProps && <LoadingButton {...cancelButtonProps} fullWidth={!secondaryActionButtonProps} variant="outlined" color="inherit">
									{cancelButtonProps.children}
								</LoadingButton>}
							{!!actionButtonProps && <LoadingButton {...actionButtonProps} fullWidth={!secondaryActionButtonProps}>
									{actionButtonProps.children}
								</LoadingButton>}
						</Stack>
					</Stack>
				</DialogActions>}
		</MuiDialog>;
};