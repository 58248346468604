import { useQuery } from "@tanstack/react-query";

import { hubGqlClient } from "~/libs/gql";
import { setState, useVenueId } from "~/store";
import { graphql } from "~/types/__generated/gql";
import { GetCurrentVenueQuery } from "~/types/__generated/gql/graphql";
import { validateSessionFromUrl } from "~/utils/validateSessionFromUrl";

const query = /* GraphQL */ `
	query GetCurrentVenue {
		getCurrentVenue {
			_id
			created_at
			updated_at
			name
			description
			address
			timezone
			logo
			banner_img
			is_self_served
			allow_comments
			item_types
			cancelled_reason_options
			custom_payment_types {
				label
				value
				is_deactivated
			}
			shifts {
				title
				startTime
				endTime
			}
			auto_print_checklist
			currencies_configs {
				code
				rate
				previous_rate
				is_primary_currency
				is_secondary_currency
				is_deactivated
			}
			fcm_tokens
			is_service_charge_buried
			service_charge
			is_vat_buried
			vat
			theme {
				primary_color
				primary_text_color
			}
			is_ordering_requires_customer
			is_qr_ordering_only
			custom_note_instructions
			is_ordering_requires_note
			order_notification_dings
			is_payway_integration_enabled
			is_internal_receipt_enabled
			is_language_localization_enabled
			is_required_headcount_before_payment
			is_payment_confirmation_enabled
			is_payment_rounding_enabled
		}
	}
`;

export type GetCurrentVenueResults = GetCurrentVenueQuery["getCurrentVenue"];

export const getCurrentVenueQueryKey = () => ["venue"];

export const useGetCurrentVenue = (enabled?) => {
	const venueId = useVenueId();

	return useQuery({
		enabled: enabled ?? !!venueId,
		queryKey: getCurrentVenueQueryKey(),
		queryFn: async () => {
			const { isSessionExpired, isPathnameSessionSensitive } =
				validateSessionFromUrl();

			if ((isPathnameSessionSensitive() && isSessionExpired()) || !venueId)
				return Promise.resolve(undefined);

			const { getCurrentVenue: venue } = await hubGqlClient.request(
				graphql(query),
			);
			return venue;
		},
		onSuccess: (data) => {
			if (!data) return;

			setState({ venueId: data._id, isReady: true });
		},
	});
};

export const useGetCurrentVenueCache = () => {
	return useGetCurrentVenue(false);
};

export const getCurrentVenueCache = () => {
	return window.$queryClient?.getQueryData<GetCurrentVenueResults>(
		getCurrentVenueQueryKey(),
	);
};
