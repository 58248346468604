import { Client } from "graphql-ws";
import Router from "next/router";

import { setState } from "~/store";
import { SubscribeToMenuSubscription } from "~/types/__generated/gql/graphql";
import {
	GetActiveMenuForQrResults,
	getActiveMenuForQrQueryKey,
} from "../useGetActiveMenuForQr";

const query = /* GraphQL */ `
	subscription SubscribeToMenu($id: ObjectID!) {
		subscribeToMenu(id: $id) {
			type
			id
			payload {
				_id
				created_at
				updated_at
				name
				description
				banner_img
				enabled
				venue
				_categories {
					_id
					created_at
					updated_at
					title
					is_template
					clone_from
					ordering_index

					menu
					venue
					_qr_items {
						_id
						created_at
						updated_at
						item_id
						title
						description
						banner_img
						is_qr_enabled
						is_pos_enabled
						no_vat
						no_service_charge
						type
						prep_time
						is_template
						clone_from
						ordering_index
						no_vat
						no_service_charge

						extra_quantity
						printer_tag
						original_price
						minimum_required_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						category
						menu
						venue
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
						options {
							ordering_index
							is_hide_from_receipt
							_option {
								_id
								created_at
								updated_at
								option_id
								title
								is_template
								clone_from

								original_price
								original_price_addons {
									vat {
										percentage
										amount
									}
									service_charge {
										percentage
										amount
									}
								}
								listed_price
								menu
								venue
								recipe {
									raw_material
									unit_of_measurement
									consumed_unit_of_measurement
									consumed_quantity
								}
							}
						}
						option_groups {
							is_required
							minimum_select
							maximum_select
							ordering_index
							options {
								ordering_index
								is_hide_from_receipt
								_option {
									_id
									created_at
									updated_at
									option_id
									title
									is_template
									clone_from

									original_price
									original_price_addons {
										vat {
											percentage
											amount
										}
										service_charge {
											percentage
											amount
										}
									}
									listed_price
									menu
									venue
									recipe {
										raw_material
										unit_of_measurement
										consumed_unit_of_measurement
										consumed_quantity
									}
								}
							}
							_option_group {
								_id
								title
								options {
									ordering_index
									_option {
										_id
										created_at
										updated_at
										option_id
										title
										is_template
										clone_from

										original_price
										original_price_addons {
											vat {
												percentage
												amount
											}
											service_charge {
												percentage
												amount
											}
										}
										listed_price
										menu
										venue
										recipe {
											raw_material
											unit_of_measurement
											consumed_unit_of_measurement
											consumed_quantity
										}
									}
								}
								clone_from
							}
						}
					}
				}
			}
		}
	}
`;

export const subscribeToMenu = async (client: Client, menuId: string) => {
	const subscription = client.iterate<SubscribeToMenuSubscription>({
		query,
		variables: { id: menuId },
	});
	for await (const result of subscription) {
		const updatedMenuData =
			window.$queryClient?.getQueryData<GetActiveMenuForQrResults>(
				getActiveMenuForQrQueryKey(),
			);

		if (updatedMenuData && result.data?.subscribeToMenu?.payload) {
			window.$queryClient?.setQueryData<GetActiveMenuForQrResults>(
				getActiveMenuForQrQueryKey(),
				result.data.subscribeToMenu.payload,
			);
			setState((prev) => ({ cart: { ...prev.cart, items: [] } }));
			void Router.replace({ pathname: "/menu", query: Router.query });
		}
	}
};
