import { Box, CircularProgress, Paper, Snackbar, Stack, Typography } from "@mui/material";
import useNavigatorOnline from "use-navigator-online";
import { Dialog } from "~served/ui-comps";
import { useIsConnectedToServer } from "~/store";
const OfflineDialog = () => {
  const {
    isOffline
  } = useNavigatorOnline();
  const isConnectedToServer = useIsConnectedToServer();
  if (isOffline) return <Dialog dialogProps={{
    maxWidth: "xs"
  }} title="No internet connection 🚫😥" content={<Box sx={{
    display: "flex",
    alignItems: "center",
    gap: 2
  }}>
						<Box>
							<CircularProgress size="32px" />
						</Box>
						<Typography variant="body2">
							You are currently offline. Check your device connection and try
							again.
						</Typography>
					</Box>} />;
  if (!isConnectedToServer) return <Snackbar open>
				<Paper variant="elevation" elevation={12} sx={{
      p: 2,
      maxWidth: "300px"
    }}>
					<Stack>
						<Typography variant="body1" gutterBottom fontWeight={600}>
							Lost real time connection 🚫😥
						</Typography>
						<Stack direction="row" alignItems="center" gap={2}>
							<Box>
								<CircularProgress size={32} />
							</Box>
							<Typography variant="body2">
								You might need to refresh some parts of the app to see the
								latest data.
							</Typography>
						</Stack>
					</Stack>
				</Paper>
			</Snackbar>;
  return null;
};
export { OfflineDialog };