import { NextSeo, NextSeoProps } from "next-seo";
import { OpenGraphMedia } from "next-seo/lib/types";
import { FC } from "react";
import { useGetCurrentVenueCache } from "~/queries/useGetCurrentVenue";
const DEFAULT_TITLE = "served consumer";
const DEFAULT_DESCRIPTION = "Scan our QRs at one of our many partners' restaurants to start placing order. Get served now!";
const DEFAULT_URL = process.env.CONSUMER_APP_URL;
const DEFAULT_IMAGE: OpenGraphMedia = {
  width: 1200,
  height: 630,
  url: `${process.env.CONSUMER_APP_URL}/images/favicon.png`,
  secureUrl: `${process.env.CONSUMER_APP_URL}/images/favicon.png`,
  alt: "served consumer logo",
  type: "image/png"
};
export interface SeoProps {
  title?: string;
  description?: string;
}
export const Seo: FC<SeoProps> = ({
  title,
  description
}) => {
  const {
    data: venue
  } = useGetCurrentVenueCache();
  const twitterProps: NextSeoProps["twitter"] = {
    cardType: "summary_large_image"
  };
  const openGraphProps: NextSeoProps["openGraph"] = {
    description: description ?? DEFAULT_DESCRIPTION,
    siteName: "served consumer",
    title: title ?? DEFAULT_TITLE,
    type: "website",
    url: DEFAULT_URL,
    images: [DEFAULT_IMAGE]
  };
  const nextSeoProps: NextSeoProps = {
    title: title ?? DEFAULT_TITLE,
    canonical: DEFAULT_URL,
    description: description ?? DEFAULT_DESCRIPTION,
    titleTemplate: venue ? `%s - ${venue.name} - served consumer` : "%s - served consumer",
    defaultTitle: "served consumer",
    twitter: twitterProps,
    openGraph: openGraphProps,
    nofollow: true,
    noindex: true,
    additionalLinkTags: [{
      rel: "manifest",
      href: "/manifest.json"
    }, {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: venue ? venue.logo : "/images/favicon.png"
    }, {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: venue ? venue.logo : "/images/favicon.png"
    }, {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: venue ? venue.logo : "/images/favicon.png"
    }, {
      rel: "icon",
      href: venue ? venue.logo : "/images/favicon.png"
    }],
    additionalMetaTags: [{
      name: "viewport",
      content: "width=device-width, initial-scale=1.0"
    }, {
      name: "application-name",
      content: "served-consumer"
    }, {
      name: "apple-mobile-web-app-title",
      content: "served-consumer"
    }, {
      name: "format-detection",
      content: "telephone=no"
    }]
  };
  return <NextSeo {...nextSeoProps} data-sentry-element="NextSeo" data-sentry-component="Seo" data-sentry-source-file="Seo.tsx" />;
};