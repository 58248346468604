import { Client } from "graphql-ws";
import { useEffect } from "react";
import { DefaultThemeProvider } from "~served/ui-comps";
import { useInitApp } from "~/hooks/useInitApp";
import { getHubGqlWsClient } from "~/libs/gql";
import { useGetCurrentVenueCache } from "~/queries/useGetCurrentVenue";
import { useGetOrderCache } from "~/queries/useGetOrder";
import { subscribeToMenu } from "~/queries/useSubscribeToCurrentMenu";
import { subscribeToCurrentVenue } from "~/queries/useSubscribeToCurrentVenue";
import { subscribeToLocation } from "~/queries/useSubscribeToLocation";
import { subscribeToOrder } from "~/queries/useSubscribeToOrder";
import { subscribeToOrderPaymentTypeAcceptEvent } from "~/queries/useSubscribeToOrderPaymentTypeAcceptEvent";
import { subscribeToSystemHealth } from "~/queries/useSubscribeToSystemHealth";
import { useLocationId, useMenuId, useOrderId, useOrderRef, useToken, useVenueId } from "~/store";
const AppProvider = ({
  children
}) => {
  useInitApp();
  useWsSubscriptions();
  const {
    data
  } = useGetCurrentVenueCache();
  return <DefaultThemeProvider venueTheme={data?.theme} data-sentry-element="DefaultThemeProvider" data-sentry-component="AppProvider" data-sentry-source-file="AppProvider.tsx">
			{children}
		</DefaultThemeProvider>;
};
export { AppProvider };
let client: Client | undefined;
const useWsSubscriptions = () => {
  const token = useToken();
  const venueId = useVenueId();
  const locationId = useLocationId();
  const menuId = useMenuId();
  const orderId = useOrderId();
  const orderRef = useOrderRef();
  const {
    data: orderCache
  } = useGetOrderCache();
  useEffect(() => {
    async function start() {
      if (client) {
        await client.dispose();
        client.terminate();
      }
      client = getHubGqlWsClient();

      /**
       * put all subscriptions here
       * so that when params change, the client is re-initialized with all the subscriptions
       */

      void subscribeToSystemHealth(client);
      if (!!token && !!venueId) void subscribeToCurrentVenue(client);
      if (!!token && !!locationId) void subscribeToLocation(client, locationId);
      if (!!token && !!menuId) void subscribeToMenu(client, menuId);
      if (orderId) void subscribeToOrder(client, {
        orderId
      });
      if (orderRef) void subscribeToOrder(client, {
        orderRef
      });
      if (orderId && orderCache?.is_needing_payment_confirmation) void subscribeToOrderPaymentTypeAcceptEvent(client, orderId);
    }
    void start();
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async function () {
        if (client) {
          await client.dispose();
          client.terminate();
        }
      })();
    };
  }, [token, venueId, locationId, menuId, orderId, orderRef, orderCache]);
};