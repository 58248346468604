/**
 * @deprecated
 * This file is deprecated and will be removed in the future.
 * Use the GraphQLError class from the graphql package instead
 */
class APIError extends Error {
	public status;
	public statusCode;

	constructor(message: string, status = 400) {
		super(message);
		this.status = status;
		this.statusCode = status;
	}
}

const badRequest = new APIError("Mismatch params, routes, or payload");
const cannotDeactivateSelf = new APIError("Cannot deactivate self");
const categoryNotFound = new APIError("Category not found", 404);
const customerAlreadyExist = new APIError(
	"Customer already exist with this phone number",
);
const customerNotFound = new APIError("Customer not found");
const deviceNotFound = new APIError("Device not found", 404);
const invalidPaymentType = new APIError("Invalid payment type");
const itemNotFound = new APIError("Item not found", 404);
const locationHasNoTab = new APIError("Location has no tab");
const locationNotClosed = new APIError("Location not fully closed yet");
const locationNotFound = new APIError("Location not found", 404);
const menuNotFound = new APIError("Menu not found", 404);
const noActiveTabsFound = new APIError("No active tabs found");
const notFound = new APIError("Requested resource not found", 404);
const notificationNotFound = new APIError("Notification not found", 404);
const noUnpaidOrders = new APIError("No unpaid orders remain");
const offerNotFound = new APIError("Offer not found", 404);
const optionNotFound = new APIError("Option not found", 404);
const orderIndexingFailed = new APIError("Failed to assign order number");
const orderNotCancelled = new APIError("Order is not cancelled");
const orderNotCreated = new APIError("Order data is incorrect");
const orderNotFound = new APIError("Order not found", 404);
const orderNoteIsRequired = new APIError(
	"Order note is required to place order",
);
const paywayPaymentFailed = new APIError("PayWay payment failed");
const serverError = new APIError("Internal server error occured", 500);
const staffNotCreated = new APIError("Staff not created");
const staffNotFound = new APIError("No staffs found", 404);
const storePrintLog = new APIError("Fail to store print log");
const tabNotFound = new APIError("Tba not found", 404);
const tempOrderNotFound = new APIError("Temp order not found", 404);
const tokenExpired = new APIError("Expired session");
const tokenInvalid = new APIError("Invalid token", 401);
const unauthorized = new APIError("Not authorized", 401);
const unknownPrintLogType = new APIError("Unknown print log type");
const userNotFound = new APIError("User not found", 404);
const venueNotFound = new APIError("Venue not found", 404);
const loginIncorrect = new APIError("Incorrect login details", 401);
const ordersAlreadyPaid = new APIError(
	"One or more of the given orders was already paid for",
);
const receiptNotFound = new APIError("Given receipt not found", 404);
const serviceUnderMaintenance = new APIError(
	"Requested resources currently under maintenance",
	503,
);
const ordersNotPrepared = new APIError(
	"One or more more orders has not been prepared yet",
	400,
);
const incorrectPaymentsAmount = new APIError("Incorrect payments amount", 400);
const rawMaterialSupplierNotFound = new APIError(
	"Raw material supplier not found",
	404,
);
const rawMaterialCategoryNotFound = new APIError(
	"Raw material category not found",
	404,
);
const rawMaterialUnitofMeasurementNotFound = new APIError(
	"Unit of Measurement not found",
	404,
);
const optionNotCreated = new APIError("Option not created");

export {
	APIError,
	badRequest,
	cannotDeactivateSelf,
	categoryNotFound,
	customerAlreadyExist,
	customerNotFound,
	deviceNotFound,
	incorrectPaymentsAmount,
	invalidPaymentType,
	itemNotFound,
	locationHasNoTab,
	locationNotClosed,
	locationNotFound,
	loginIncorrect,
	menuNotFound,
	noActiveTabsFound,
	noUnpaidOrders,
	notFound,
	notificationNotFound,
	offerNotFound,
	optionNotFound,
	orderIndexingFailed,
	orderNotCancelled,
	orderNotCreated,
	orderNotFound,
	orderNoteIsRequired,
	ordersAlreadyPaid,
	ordersNotPrepared,
	paywayPaymentFailed,
	rawMaterialCategoryNotFound,
	rawMaterialSupplierNotFound,
	rawMaterialUnitofMeasurementNotFound,
	receiptNotFound,
	serverError,
	serviceUnderMaintenance,
	staffNotCreated,
	staffNotFound,
	storePrintLog,
	tabNotFound,
	tempOrderNotFound,
	tokenExpired,
	tokenInvalid,
	unauthorized,
	unknownPrintLogType,
	userNotFound,
	venueNotFound,
	optionNotCreated,
};
