import { Client } from "graphql-ws";

import {
	DATA_EVENT_TYPE,
	SubscribeToOrderSubscription,
} from "~/types/__generated/gql/graphql";
import { GetOrderResults, getOrderQueryKey } from "../useGetOrder";

const query = /* GraphQL */ `
	subscription SubscribeToOrder($orderId: ObjectID, $ref: String) {
		subscribeToOrder(orderId: $orderId, ref: $ref) {
			type
			id
			payload {
				_id
				created_at
				updated_at
				index
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				changelogs {
					created_at
					updated_at
					user
					identifier
					old_value
					new_value
					type
				}
				user
				customer
				venue
				status
				is_paid
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				items {
					_id
					category
					item
					item_id
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						option_id
						uid
						title
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
						is_hide_from_receipt
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}
				cancelled_items {
					_id
					category
					item
					item_id
					title
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						option_id
						uid
						title
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
						is_hide_from_receipt
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
				is_request_payment_confirmation

				_current_location {
					_id
					name
					type
					hash
				}
				_customer {
					_id
					first_name
					last_name
				}
				_receipt {
					_id
					created_at
					updated_at
					payment_types {
						payment_type
						code
						amount
					}
				}
			}
		}
	}
`;

export const subscribeToOrder = async (
	client: Client,
	{ orderId, orderRef }: { orderId?: string; orderRef?: string },
) => {
	const subscription = client.iterate<SubscribeToOrderSubscription>({
		query,
		variables: { orderId, ref: orderRef },
	});
	for await (const result of subscription) {
		if (result.data) {
			const { type, payload } = result.data.subscribeToOrder;

			if (type === DATA_EVENT_TYPE.delete || !payload) return;

			window.$queryClient?.setQueryData<GetOrderResults>(
				getOrderQueryKey(orderId ?? orderRef),
				(pre) => {
					if (!pre) return pre;

					return {
						...pre,
						...payload,
					};
				},
			);
		}
	}
};
