import _d from "dayjs";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

_d.extend(timezone);
_d.extend(relativeTime);
_d.extend(utc);
_d.extend(duration);
_d.extend(isToday);
_d.extend(isToday);
_d.extend(isSameOrAfter);
_d.extend(isSameOrBefore);
_d.extend(isBetween);

export const dayjs = _d;
export const TIMESTRING_FORMAT = "HH:mm:ss.SSS";
export const DATESTRING_FORMAT = "YYYY-MM-DD";
