import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { Database, getDatabase } from "firebase/database";
import { Messaging, getMessaging } from "firebase/messaging";

const firebaseConfig = {
	apiKey: process.env.FB_API_KEY,
	authDomain: process.env.FB_AUTH_DOMAIN,
	databaseURL: process.env.FB_DATABASE_URL,
	projectId: process.env.FB_PROJECT_ID,
	storageBucket: process.env.FB_STORAGE_BUCKET,
	messagingSenderId: process.env.FB_MESSAGING_SENDER_ID,
	appId: process.env.FB_APP_ID,
	measurementId: process.env.FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
let database: Database;
let messaging: Messaging;
let analytics: Analytics;

if (typeof window === "object") {
	try {
		database = getDatabase(app);
		messaging = getMessaging(app);
		analytics = getAnalytics(app);
	} catch (e) {
		console.log("e: ", e);
	}
}

export { analytics, database, messaging };
