import { Client } from "graphql-ws";

import { setState } from "~/store";
import {
	SubscribeToSystemHealthSubscription,
	SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE,
} from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	subscription SubscribeToSystemHealth {
		subscribeToSystemHealth {
			type
		}
	}
`;

export const subscribeToSystemHealth = async (client: Client) => {
	const subscription = client.iterate<SubscribeToSystemHealthSubscription>({
		query,
	});
	for await (const result of subscription) {
		if (result.data) {
			const { type } = result.data.subscribeToSystemHealth;
			switch (type) {
				case SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE.init: {
					setState({ isConnectedToServer: true });
					break;
				}
				case SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE.new_version_available: {
					setState({ isLatest: false });
					break;
				}
				case SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE.shutdown: {
					setState({ isConnectedToServer: false });
					break;
				}
			}
		}
	}
};
