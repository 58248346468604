import { Component } from "react";
import { Error } from "../Error";
class ErrorBoundary extends Component<{
  appName: string;
  id: string;
  token: string;
}, {
  hasError: boolean;
}> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error: Error, errorInfo) {
    console.log("[APP ERROR BOUNDARY]: ", {
      error,
      errorInfo,
      location: window.location
    });
  }
  render() {
    if (this.state.hasError) {
      return <Error data-sentry-element="Error" data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx" />;
    }
    return this.props.children;
  }
}
export { ErrorBoundary };