import { GraphQLClient, ClientError } from "graphql-request";
import { RequestMiddleware, Response } from "graphql-request/build/esm/types";
import { ClientOptions, createClient } from "graphql-ws";

import { getState, setState } from "~/store";

import { browserStorage } from "./localforage";

export { getHubGqlWsClient, hubGqlClient };

const getAuthHeaders = async () => {
	const obj = {} as Record<string, string>;

	const {
		venueId,
		token: _token,
		locationId,
		deviceId: _deviceId,
	} = getState();
	const token = _token ?? (await browserStorage.token.get());
	const deviceId = _deviceId ?? (await browserStorage.deviceId.get());

	if (token && obj.Authorization === undefined)
		obj.Authorization = `Bearer ${token}`;
	if (venueId) obj["x-served-venue-id"] = venueId;
	if (locationId) obj["x-served-location-id"] = locationId;
	if (deviceId) obj["x-served-device-id"] = deviceId;
	obj["x-served-consumer-app-signature"] = new Date().getTime().toString();

	return obj;
};

const requestMiddleware: RequestMiddleware = async (request) => {
	const authHeaders = await getAuthHeaders();

	return { ...request, headers: { ...request.headers, ...authHeaders } };
};

const getErrorMessages = (response: Response<unknown> | ClientError) => {
	let messages = "";

	if (response instanceof ClientError) {
		messages =
			response.response.errors?.[0]?.message ?? "Something went wrong!";
	}

	return messages;
};

const responseMiddleware = (response: Response<unknown> | ClientError) => {
	const messages = getErrorMessages(response);
	if (messages) {
		console.log("=== GQL CLIENT ERROR ===", messages);
		setState({ error: messages });
	}
};

const getHubGqlWsClient = () => {
	return createClient({
		url: process.env.HUB_GQL_WS_URL!,
		keepAlive: 5000,
		shouldRetry: () => true,
		retryAttempts: Infinity,
		retryWait: () => new Promise((resolve) => setTimeout(resolve, 2000)),
		connectionParams: getAuthHeaders,
		on: getWsClientLogger(),
	});
};

const hubGqlClient = new GraphQLClient(process.env.HUB_GQL_URL!, {
	requestMiddleware,
	// @ts-expect-error - responseMiddleware is not properly typed
	responseMiddleware,
});

let timer: NodeJS.Timeout | null = null;

const getWsClientLogger = (): ClientOptions["on"] => {
	return {
		connected: () => {
			if (timer) clearTimeout(timer);

			setState({ isConnectedToServer: true });
		},
		closed: () => {
			// Socket could be forcibly terminated by the client since WS params could be changed
			timer = setTimeout(() => {
				if (!getState().isConnectedToServer)
					setState({ isConnectedToServer: false });
			}, 5000);
		},
		error: () => {
			setState({ isConnectedToServer: false });
		},
		// connecting: () => {},
		// message: () => {},
		// opened: () => {},
		// ping: () => {},
		// pong: () => {},
	};
};
