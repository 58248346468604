export enum ORDER_CHANGELOG_TYPE {
	CHANGE_ITEM_QUANTITY = "change_item_quantity",
	CANCELLED_ITEM = "cancelled_item",
	CHANGE_DISCOUNT = "change_discount",
	CANCELLED_ORDER_SPLIT = "cancelled_order_split",
	CANCELLED_ORDER_NO_ITEMS = "cancelled_order_no_items",
	CANCELLED_ORDER_MOVED = "cancelled_order_moved",
	SPLIT_ITEM_SOME = "split_item_some",
	SPLIT_ITEM_WHOLE = "split_item_whole",
	MOVE_ITEM_SOME = "move_item_some",
	MOVE_ITEM_WHOLE = "move_item_whole",
	UPDATE_ORDER_PREP_TIME = "update_order_prep_time",
	CUSTOMER_ACCEPTED_PREP_TIME_UPDATES = "customer_accepted_prep_time_updates",
	CUSTOMER_REJECTED_PREP_TIME_UPDATES = "customer_rejected_prep_time_updates",
}

/** @deprecated start using embedded `base_currency` and `currencies_configs` instead */
export enum EXCHANGE_RATE {
	USD_KHR = 4150,
}

/**
 * Built in payment types, each venue will have its own custom payment types to be used at Till on top of these.
 * This is a broad enum, will soon migrate to a specific enum that contain subset of this enum for each use case
 * */
export enum PAYMENT_TYPE {
	KPAY_CARD = "kpay_card",
	KPAY_QR = "kpay_qr",
	KPAY_QR_REVERSED = "kpay_qr_reversed",
	KPAY_OCTOPUS = "kpay_octopus",
	KPAY_OCTOPUS_QR = "kpay_octopus_qr",
	KPAY_PAYME = "kpay_payme",
	KPAY_PAYME_REVERSED = "kpay_payme_reversed",
	/** Any payment come in through Served's PayWay integration */
	SERVED_PAYWAY = "served_payway",
	/** ABA PAY payment at venue */
	ABA_PAY = "aba_pay",
	/** KHQR payment at venue */
	KHQR = "khqr",
	/** or `Pay Later`, should NOT be stored as `payment_type`, only for displaying purpose */
	TAB = "tab",
}

export enum PAYMENT_TYPE_TITLE {
	kpay_card = "KPay Card",
	kpay_qr = "KPay QR",
	kpay_qr_reversed = "KPay QR Reversed",
	kpay_octopus = "KPay Octopus",
	kpay_octopus_qr = "KPay Octopus QR",
	kpay_payme = "KPay PayMe",
	kpay_payme_reversed = "KPay PayMe Reversed",
	served_payway = "Served - PayWay",
	aba_pay = "ABA PAY",
	khqr = "KHQR",
	tab = "Tab",
}

export enum ORDER_STATUS {
	PLACED = "placed",
	PREPARING = "preparing",
	PROCESSED = "processed",
	/** @deprecated use `PROCESSED` */
	READY_TO_COLLECT = "ready_to_collect",
	/** @deprecated use `PROCESSED` */
	ON_THE_WAY = "on_the_way",
	/** @deprecated use `is_cancelled` flag */
	CANCELLED = "cancelled",
}

export enum USER_ROLE {
	SUPER_ADMIN = "super_admin",
	ADMIN = "admin",
	MANAGER = "manager",
	SUPERVISOR = "supervisor",
	STAFF = "staff",
	JUNIOR_STAFF = "junior_staff",
}

export enum OPS_PAGE {
	TILL = "till",
	ORDERS = "orders",
	LOCATIONS = "locations",
	KDS = "kds",
	RESERVATION = "reservation",
}

export enum USER_PERMISSIONS {
	VIEW_ORDER = "VIEW_ORDER",
	CREATE_ORDER = "CREATE_ORDER",
	EDIT_ORDER_BEFORE_BILL_PRINTED = "EDIT_ORDER_BEFORE_BILL_PRINTED",
	EDIT_ORDER_AFTER_BILL_PRINTED = "EDIT_ORDER_AFTER_BILL_PRINTED",
	EDIT_ORDER_BEFORE_PAYMENT = "EDIT_ORDER_BEFORE_PAYMENT",
	EDIT_ORDER_AFTER_PAYMENT = "EDIT_ORDER_AFTER_PAYMENT",
	CANCEL_ORDER_BEFORE_BILL_PRINTED = "CANCEL_ORDER_BEFORE_BILL_PRINTED",
	CANCEL_ORDER_AFTER_BILL_PRINTED = "CANCEL_ORDER_AFTER_BILL_PRINTED",
	CANCEL_ORDER_BEFORE_PAYMENT = "CANCEL_ORDER_BEFORE_PAYMENT",
	CANCEL_ORDER_AFTER_PAYMENT = "CANCEL_ORDER_AFTER_PAYMENT",
	CANCEL_RECEIPT = "CANCEL_RECEIPT",
	OPEN_CASHDRAWER = "OPEN_CASHDRAWER",
	AUTO_LOGOUT = "AUTO_LOGOUT",
}

export enum GENDER {
	MALE = "male",
	FEMALE = "female",
	OTHER = "other",
}

export enum ITEM_TYPE {
	FOOD = "food",
	DRINK = "drink",
}

export enum LOCATION_TYPE {
	TABLE = "table",
	PICK_UP_POINT = "pick_up_point",
}

export enum LOCATION_STATUS {
	OCCUPIED = "occupied",
	OPEN = "open",
}

export enum GEOLOCATION_TYPE {
	LOCATION = "location",
	CUSTOM = "custom",
}

export enum GEOMETRIC_SHAPE_TYPE {
	CIRCLE = "circle",
	SQUARE = "square",
	RECTANGLE = "rectangle",
}

export enum ITEM_STATUS {
	PREPARING = "preparing",
	DELIVERED = "delivered",
}

export enum TAB_STATUS {
	OPEN = "open",
	CLOSED = "closed",
}

export enum DISCOUNT_TYPE {
	AMOUNT = "amount",
	PERCENTAGE = "percentage",
}

export enum FEE_CHARGING_METHODS {
	LINE_ITEM = "line_item",
	BURIED_IN_MENU = "buried_in_menu",
}

/** @deprecated don't need to use anymore until new print log type is needed */
export enum PRINT_LOG_TYPE {
	TICKET = "ticket",
	TAB_RECEIPT = "tab-receipt",
	ORDER_RECEIPT = "order-receipt",
	ORDER_INVOICE = "order-invoice",
	TAB_INVOICE = "tab-invoice",
}

export enum ORDER_SOURCE {
	QR = "qr",
	TILL = "till",
}

export enum USER_TITLES {
	MR = "mr",
	MRS = "mrs",
	MS = "ms",
}

export enum OFFER_TYPE {
	ITEMS = "items",
	CATEGORIES = "categories",
	ITEM_TYPES = "item_types",
	RECEIPTS = "receipts",
}

export enum PRINT_TYPE {
	CHECKLIST = "checklist",
	TICKET = "ticket",
	RECEIPT = "receipt",
	REPORT = "report",
	CUSTOM = "custom",
	CANCELLED_ORDER = "cancelled_order",
	KITCHEN_MESSAGE = "kitchen_message",
}

export enum RECEIPT_TYPE_PREFIX {
	INTERNAL_RECEIPT = "IR",
	EXTERNAL_RECEIPT = "",
}

export enum TAG_TYPES {
	LOCATION_GROUP = "location_group",
	PRINTER = "printer",
}

export enum DISCOUNT_EFFECTIVE_LEVEL {
	ITEM = 0,
	ORDER = 1,
	ORDER_GROUP = 2,
}

export enum ACCOUNTING_JOURNAL_TRANSACTION_TYPE {
	RECEIVABLE = "receivable",
	PAYABLE = "payable",
}

export enum CUSTOM_REPORT_DISPLAY_TYPE {
	DOUGHNUT = "doughnut",
	TABLE = "table",
	// BAR = 'bar',
}

export enum CUSTOM_REPORT_VALUE_FIELD {
	UNIT_PRICE = "unitPrice",
	COUNT = "count",
	SUB_TOTAL = "subtotal",
	DISCOUNT = "discount",
	OFFER = "offer",
	NET_SALES = "netSales",
	SERVICE_CHARGE = "serviceCharge",
	VAT = "vat",
	GROSS_SALES = "grossSales",
	ADJUSTMENT = "adjustment",
	CANCELLED_VALUE = "cancelledValue",
}

export enum CUSTOM_REPORT_VALUE_TYPE {
	CATEGORY = "category",
	ITEM_TYPE = "itemType",
	ITEM = "item",
	OPTION = "option",
	LOCATION = "location",
	LOCATION_GROUP_TAG = "locationGroupTag",
	PAYMENT_TYPE = "paymentType",
	OFFER = "offer",
}

/** @deprecated kept only for `api` usage, should be use the generated types from `hub` gql schema instead */
export enum UPLOAD_TYPES {
	ITEM = "item",
	MENU = "menu",
	VENUE_BANNER = "venue_banner",
	VENUE_LOGO = "venue_logo",
}

export const KPAY_SALES_REQUEST_PAYMENT_TYPE = {
	[PAYMENT_TYPE.KPAY_CARD]: 1,
	[PAYMENT_TYPE.KPAY_QR]: 2,
	[PAYMENT_TYPE.KPAY_QR_REVERSED]: 3,
	[PAYMENT_TYPE.KPAY_OCTOPUS]: 4,
	[PAYMENT_TYPE.KPAY_OCTOPUS_QR]: 5,
	[PAYMENT_TYPE.KPAY_PAYME]: 6,
	[PAYMENT_TYPE.KPAY_PAYME_REVERSED]: 7,
};

export const KPAY_TRANSACTION_PAY_RESULT = {
	TIMEOUT: -1,
	PENDING: 1,
	SUCCESSFUL: 2,
	FAILED: 3,
	RETURNED: 4,
	REVOKED: 5,
	CANCELED: 6,
};

export const KPAY_TRANSACTION_PAY_METHOD = {
	VISA: 1,
	MASTERCARD: 2,
	CHINA_UNION_PAY: 3,
	WE_CHAT: 4,
	ALIPAY: 5,
	AMERICAN_EXPRESS: 6,
	DINERS_CLUB: 7,
	JCB: 8,
	UNION_PAY_QUICK_PASS: 9,
	OCTOPUS: 11,
	PAYME: 12,
};

export const KPAY_RESPONSE_CODE = {
	SUCCESS: 10000,
};

export const KPAY_TRANSACTION_PAY_METHOD_LABEL = {
	[KPAY_TRANSACTION_PAY_METHOD.VISA]: "Visa",
	[KPAY_TRANSACTION_PAY_METHOD.MASTERCARD]: "Mastercard",
	[KPAY_TRANSACTION_PAY_METHOD.CHINA_UNION_PAY]: "China UnionPay",
	[KPAY_TRANSACTION_PAY_METHOD.WE_CHAT]: "WeChat",
	[KPAY_TRANSACTION_PAY_METHOD.ALIPAY]: "Alipay",
	[KPAY_TRANSACTION_PAY_METHOD.AMERICAN_EXPRESS]: "American Express",
	[KPAY_TRANSACTION_PAY_METHOD.DINERS_CLUB]: "Diners Club",
	[KPAY_TRANSACTION_PAY_METHOD.JCB]: "JCB",
	[KPAY_TRANSACTION_PAY_METHOD.UNION_PAY_QUICK_PASS]: "UnionPay QuickPass",
	[KPAY_TRANSACTION_PAY_METHOD.OCTOPUS]: "Octopus",
	[KPAY_TRANSACTION_PAY_METHOD.PAYME]: "Payme",
};

export const KPAY_TRANSACTION_PAY_METHOD_TO_SALES_REQUEST_PAYMENT_TYPE = {
	[KPAY_TRANSACTION_PAY_METHOD.VISA]: PAYMENT_TYPE.KPAY_CARD,
	[KPAY_TRANSACTION_PAY_METHOD.MASTERCARD]: PAYMENT_TYPE.KPAY_CARD,
	[KPAY_TRANSACTION_PAY_METHOD.CHINA_UNION_PAY]: PAYMENT_TYPE.KPAY_CARD,
	[KPAY_TRANSACTION_PAY_METHOD.WE_CHAT]: PAYMENT_TYPE.KPAY_QR,
	[KPAY_TRANSACTION_PAY_METHOD.ALIPAY]: PAYMENT_TYPE.KPAY_QR,
	[KPAY_TRANSACTION_PAY_METHOD.AMERICAN_EXPRESS]: PAYMENT_TYPE.KPAY_CARD,
	[KPAY_TRANSACTION_PAY_METHOD.DINERS_CLUB]: PAYMENT_TYPE.KPAY_CARD,
	[KPAY_TRANSACTION_PAY_METHOD.JCB]: PAYMENT_TYPE.KPAY_CARD,
	[KPAY_TRANSACTION_PAY_METHOD.UNION_PAY_QUICK_PASS]: PAYMENT_TYPE.KPAY_QR,
	[KPAY_TRANSACTION_PAY_METHOD.OCTOPUS]: PAYMENT_TYPE.KPAY_OCTOPUS,
	[KPAY_TRANSACTION_PAY_METHOD.PAYME]: PAYMENT_TYPE.KPAY_PAYME,
};

export const KPAY_PAYMENT_TYPES = [
	PAYMENT_TYPE.KPAY_CARD,
	PAYMENT_TYPE.KPAY_QR,
	PAYMENT_TYPE.KPAY_QR_REVERSED,
	PAYMENT_TYPE.KPAY_OCTOPUS,
	PAYMENT_TYPE.KPAY_OCTOPUS_QR,
	PAYMENT_TYPE.KPAY_PAYME,
	PAYMENT_TYPE.KPAY_PAYME_REVERSED,
];
