import { withStyles } from "@mui/styles";
import { cloneElement } from "react";
import { clipPathSupported } from "./util";
const clipPath = value => ({
  WebkitClipPath: value,
  clipPath: value
});
const styles: any = {
  root: {
    width: 24,
    height: 24,
    position: "relative",
    display: "inline-block"
  },
  offIcon: {
    transition: "clip-path 550ms cubic-bezier(0.4, 0.0, 0.2, 1), -webkit-clip-path 550ms cubic-bezier(0.4, 0.0, 0.2, 1)",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0
  },
  onIcon: {
    transition: "clip-path 550ms cubic-bezier(0.4, 0.0, 0.2, 1), -webkit-clip-path 550ms cubic-bezier(0.4, 0.0, 0.2, 1)",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0
  }
};

/**
 * An animated toggle icon.
 */
function ToggleIconComp(props) {
  const {
    classes,
    className,
    offIcon,
    onIcon,
    on,
    ...other
  } = props;
  return <div className={`${classes.root} ${className || ""}`} {...other} data-sentry-component="ToggleIconComp" data-sentry-source-file="index.tsx">
			{cloneElement(offIcon, {
      className: classes.offIcon,
      style: {
        ...clipPath(on ? "polygon(0% 0%, 0% 0%, 0% 0%)" : "polygon(0% 200%, 0% 0%, 200% 0%)"),
        visibility: !on || clipPathSupported() ? "visible" : "hidden"
      }
    })}
			{cloneElement(onIcon, {
      className: classes.onIcon,
      style: {
        ...clipPath(on ? "polygon(100% -100%, 100% 100%, -100% 100%)" : "polygon(100% 100%, 100% 100%, 100% 100%)"),
        visibility: on || clipPathSupported() ? "visible" : "hidden"
      }
    })}
		</div>;
}
export const ToggleIcon = withStyles(styles)(ToggleIconComp);