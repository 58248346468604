import { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
export function Confetti({
  show
}) {
  const refAnimationInstance = useRef<any>(null);
  const [intervalId, setIntervalId] = useState<any>();
  const getInstance = useCallback(instance => {
    refAnimationInstance.current = instance;
  }, []);
  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);
  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);
  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
  }, [intervalId]);
  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);
  useEffect(() => {
    if (show) {
      startAnimation();
      setTimeout(() => stopAnimation(), 3000);
    }
  }, [show]);
  return <ReactCanvasConfetti refConfetti={getInstance} style={{
    position: "fixed",
    zIndex: 1,
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  }} data-sentry-element="ReactCanvasConfetti" data-sentry-component="Confetti" data-sentry-source-file="index.tsx" />;
}
function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: -1,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2
    }
  };
}
function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}